<template>
  <v-navigation-drawer
      app
      left
      permanent
      floating
      expand-on-hover
      @mouseover.native="miniDrawer = false"
      @mouseleave.native.stop="miniDrawer = true;showProfileMenu = false;"
      :class="{ 'elevation-16': !miniDrawer, 'elevation-4': miniDrawer }"
      class="planete-online-gradient"
  >

    <profile-badge
        v-model="showProfileMenu"
        @logout="$emit('logout')"
        :miniDrawer="miniDrawer"
    ></profile-badge>

    <transition>
      <v-list>

        <menu-home></menu-home>
        <menu-modules></menu-modules>
        <menu-calendar></menu-calendar>
        <menu-hotline :mini="miniDrawer"></menu-hotline>
        <menu-news></menu-news>

        <template v-if="admin">

          <v-divider class="my-2"></v-divider>
          <menu-admin></menu-admin>

        </template>

        <template v-if="superAdmin">

          <v-divider class="my-2" ></v-divider>
          <menu-support-vision v-if="isSPVrights"></menu-support-vision>

        </template>

      </v-list>
    </transition>

    <template v-slot:append>

      <side-menu-footer :mini="miniDrawer"></side-menu-footer>

    </template>

  </v-navigation-drawer>
</template>

<script>
import Vuex from "vuex";
import AppService from "@/Services/AppService";

export default {
  name: "SideMenu",
  components: {
    ProfileBadge: () => import("@/Components/Commons/Interface/SideMenu/ProfileBadge"),
    SideMenuFooter: () => import("@/Components/Commons/Interface/SideMenu/SideMenuFooter"),

    MenuHome: () => import("@/Components/Commons/Interface/Menus/MenuHome"),
    MenuModules: () => import("@/Components/Commons/Interface/Menus/MenuModules"),
    MenuCalendar: () => import("@/Components/Commons/Interface/Menus/MenuCalendar"),
    MenuHotline: () => import("@/Components/Commons/Interface/Menus/MenuHotline"),
    MenuNews: () => import("@/Components/Commons/Interface/Menus/MenuNews"),

    MenuAdmin: () => import("@/Components/Commons/Interface/Menus/MenuAdmin"),

    MenuSupportVision: () => import("@/Components/Commons/Interface/Menus/MenuSupportVision"),
    MenuAuthentification: () => import("@/Components/Commons/Interface/Menus/MenuAuthentification"),

  },
  computed: {
    ...Vuex.mapState(["informations"]),
    superAdmin() {
      return this.informations.superAdministrator
    },
    admin() {
      return this.informations.administrator
    },
    hasNewHotlineTicket() {
      return (this.hotline || []).length
    }, 
    isSPVrights() {
      // verifier si les bases sont autorisées
      return this.autorized_bases.includes(this.baseUrl)
    }
  },
  data() {
    return {
      miniDrawer: true,
      showProfileMenu: false,
      baseUrl: null,
      autorized_bases: [
        "lean-dev.planett.net",
        "callcenter.planete-online.fr",
        "beta-locale.planett.net",
        "beta-locale.planete-online.fr",
      ]
    };
  },
  async mounted() {
    const res = await AppService.getDns()
    this.baseUrl = res ? res : null
  }
};
</script>

